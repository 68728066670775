import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import constant from "../../../shared/constant";
import { confirmAlert } from 'react-confirm-alert';

// reactstrap components
import {
  Row,
  Card,
  CardBody,
  Table,
  Button
} from "reactstrap";

// core components
import DropdownScrollNavbar from "components/shared/DropdownScrollNavbar.js";
import Footer from "components/shared/Footer.js";
import '../user-control/UserControl.css';
import Container from "reactstrap/lib/Container";
import { find } from "lodash";

class Districts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      districts: [],
      district: {}
    }
  }

  componentDidMount() {
    document.body.classList.add("user-control-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    if (this.props.auth.user.role !== constant['ROLE']['SUPER_ADMIN']) {
      this.props.history.push('/');
    } else {
      this.getDistricts();
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("user-control-page");
    document.body.classList.remove("sidebar-collapse");
  }

  getTotalObservationsByDistricts(districts) {
    axios.post('/api/observations/totalObservationCountByDistricts')
      .then((res) => {
        let districtsObj = districts.map((district) => {
          const districtData = find(res.data, { district: district._id });
          return {
            ...district,
            count: districtData ? districtData.count : 0
          }
        });
        this.setState({ districts: districtsObj });
      });
  }

  getDistricts() {
    axios
      .get('/api/districts')
      .then((res) => {
        if (res.data && res.data.length > 0)
          this.getTotalObservationsByDistricts(res.data);
      }).catch((err) => {
        console.log(err);
      });
  }

  editDistrict(district) {
    this.props.history.push(`/districts/${district._id}`);
  }

  
  editSchool(district, school) {
    this.props.history.push(`/school/${district._id}/${school._id}`);
  }

  onDeletedDistrictInformation = (district) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='confirm-dialog'>
            <h1>Are you sure?</h1>
            <p>Are you delete a District/School Information?  This can not be undone!</p>
            <button className="btn default-back-color font-crm" onClick={onClose}>Go Back</button>
            <button
              className="btn red-alert-color ml-2 font-crm"
              onClick={() => {
                onClose();
                this.removeDistrict(district);
              }}
            >
              Confirm
            </button>
          </div>
        );
      }
    });
  }

  removeDistrict(district) {
    axios
      .delete(`/api/districts/${district._id}`)
      .then((res) => {
        toast.info(res.data.message);
        this.getDistricts();
      }).catch((err) => {
        console.log(err);
      });
  }

  sendEmailToDA(email, districtId) {
    axios
      .post('/api/districts/sendEmailToDA', { email: email, districtId: districtId })
      .then((res) => {
        toast.info(res.data && res.data.message);
      }).catch((err) => {
        console.log(err);
        toast.error(typeof err.response !== "undefined" ? err.response.data.message : err.message);
      });
  }

  sendEmailToSA(email, districtId, schoolId) {
    axios
      .post('/api/districts/sendEmailToSA', { email: email, districtId: districtId, schoolId: schoolId })
      .then((res) => {
        toast.info(res.data && res.data.message);
      }).catch((err) => {
        console.log(err);
        toast.error(typeof err.response !== "undefined" ? err.response.data.message : err.message);
      });
  }

  displayManagermails(admins, district) {
    let tempAdmins = admins.filter((item) => item.archived === false).sort((a, b) => a.name > b.name ? 1 : -1)
    return tempAdmins.map((admin) => {
      return (
        <>
          <p style={{ display: 'block', text: 'left', color: 'black', fontWeight: '600' }}>
            <span style={{ display: 'block', lineHeight: '10px' }}>{admin.name}</span>
            <span className="badge default-back-color mr-1">{admin.email}</span>
            <Button className="btn-neutral" color="twitter" onClick={() => this.sendEmailToDA(admin.email, district._id)}>
              <i className="now-ui-icons ui-1_email-85"></i>
            </Button>
          </p>
          {admin.email === '' &&
            <p style={{ color: 'blue', fontSize: '0.9em', fontWeight: '500' }}>
              {`${process.env.REACT_APP_HOST_PATH}/register?district=${district._id}&role=${constant['ROLE']['DISTRICT_ADMIN']}&school=&email=${admin.email}`}
            </p>
          }
        </>
      )
    });
  }

  displayAdminEmails(admins, district) {
    let tempAdmins = admins.filter((item) => item.archived === false).sort((a, b) => a.name > b.name ? 1 : -1)
    return tempAdmins.map((admin) => {
      return (
        <>
          <p style={{ display: 'block', text: 'left', color: 'black', fontWeight: '600' }}>
            <span style={{ display: 'block', lineHeight: '10px' }}>{admin.name}</span>
            <span className="badge default-back-color mr-1">{admin.email}</span>
            <Button className="btn-neutral" color="twitter" onClick={() => this.sendEmailToDA(admin.email, district._id)}>
              <i className="now-ui-icons ui-1_email-85"></i>
            </Button>
          </p>
          {admin.email === '' &&
            <p style={{ color: 'blue', fontSize: '0.9em', fontWeight: '500' }}>
              {`${process.env.REACT_APP_HOST_PATH}/register?district=${district._id}&role=${constant['ROLE']['DISTRICT_ADMIN']}&school=&email=${admin.email}`}
            </p>
          }
        </>
      )
    });
  }

  displayUsedSchoolCount(schools, district) {
    let tempSchools  = schools.filter((item) => item.archived === false).sort((a, b) => a.name > b.name ? 1 : -1)
    return tempSchools.map((school) => {
      let count = 0;
      if(school.users.length > 0) {
        school.users.forEach(user => {
          if(user.count) {
            count += user.count;
          }
        })
      }
      return (
        <>
          <p style={{ display: 'block', text: 'left', color: 'black', fontWeight: '400', marginBottom: '19px' }}>
            {count}
          </p>
        </>
      )
    });
  }

  displaySchoolCount(schools, district) {
    let tempSchools  = schools.filter((item) => item.archived === false).sort((a, b) => a.name > b.name ? 1 : -1)
    return tempSchools.map((school) => {
      return (
        <>
          <p style={{ display: 'block', text: 'left', color: 'black', fontWeight: '400', marginBottom: '19px' }}>
            {school.count}
          </p>
        </>
      )
    })
  }

  displaySchools(schools, district) {
    let tempSchools  = schools.filter((item) => item.archived === false).sort((a, b) => a.name > b.name ? 1 : -1)
    return tempSchools.map((school) => {
      return (
        <>
          <p style={{ display: 'block', text: 'left', color: 'black', fontWeight: '600' }}>
            <span>
              <Button
                className="btn-tooltip btn-icon mr-1 edit-btn"
                style={{backgroundColor: 'transparent'}}
                size="sm"
                type="button"
                onClick={() => this.editSchool(district, school)}
              >
                <i className="now-ui-icons ui-2_settings-90" style={{color: '#55acee', fontSize: '15px'}}></i>
              </Button>
              {school.name}
            </span>
            {/* {school.email && school.email !== '' &&
              <Button className="btn-neutral" color="twitter" onClick={() => this.sendEmailToSA(school.email, district._id, school._id)}>
                <i className="now-ui-icons ui-1_email-85"></i>
              </Button>
            } */}
            {/* <span style={{ display: 'block' }}>{school.email}</span> */}
          </p>
          {/* {(!school.email || school.email === '') &&
            <p style={{ color: 'blue', fontSize: '0.9em', fontWeight: '500' }}>
              {`${process.env.REACT_APP_HOST_PATH}/register?district=${district._id}&role=${constant['ROLE']['SCHOOL_ADMIN']}&school=${school._id}&email=${school.email}`}
            </p>
          } */}
        </>
      )
    });
  }

  renderDistrictsData() {
    this.state.districts.reverse();

    return this.state.districts.map((district, index) => {
      const { name, admins, emails, schools, totalObservations, count } = district;
      return (
        <tr key={index}>
          <td className="text-center" style={{ verticalAlign: 'top' }}>{index + 1}</td>
          <td className="text-left" style={{ verticalAlign: 'top' }}><p style={{fontWeight: 400}}>{name}</p></td>
          <td className="text-left" style={{ verticalAlign: 'top' }}>{this.displayAdminEmails(emails, district)}</td>
          <td className="text-left" style={{ verticalAlign: 'top' }}>{this.displayManagermails(admins, district)}</td>
          <td className="text-left" style={{ verticalAlign: 'top' }}>{this.displaySchools(schools, district)}</td>
          <td className="text-center" style={{ verticalAlign: 'top' }}>{this.displaySchoolCount(schools, district)}</td>
          <td className="text-center" style={{ verticalAlign: 'top' }}>{this.displayUsedSchoolCount(schools, district)}</td>
          <td className="text-center" style={{ verticalAlign: 'top' }}><p style={{fontWeight: 400}}>{totalObservations}</p></td>
          <td className="text-right" style={{ verticalAlign: 'top' }}>
            <Button
              className="btn-tooltip btn-icon mr-1"
              style={{backgroundColor: '#569959'}}
              size="sm"
              type="button"
              onClick={() => this.editDistrict(district)}
            >
              <i className="now-ui-icons ui-2_settings-90"></i>
            </Button>
            <Button
              className="btn-tooltip btn-icon"
              style={{backgroundColor: '#A62E1B'}}
              size="sm"
              type="button"
              onClick={() => this.onDeletedDistrictInformation(district)}
            >
              <i className="now-ui-icons ui-1_simple-remove"></i>
            </Button>
          </td>
        </tr>
      );
    });
  }

  createDistrictData() {
    this.props.history.push("/districts/create");
  }

  render() {
    return (
      <>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <DropdownScrollNavbar />
        <div className="wrapper">
          <div className="home">
            <div className="panel">
              <Container fluid={true}>
                <Row>
                  <div className="mr-auto ml-auto col-lg-12">
                    <h3 className="title">Add, Edit or Delete District Information</h3>
                  </div>
                </Row>
                <Row>
                  <div className="mr-auto ml-auto col-lg-12">
                    <Button
                      onClick={() => { this.createDistrictData() }}
                      type="button" className="btn-round btn-cpp font-crm default-back-color">
                      Add New District
                    </Button>
                    <Card className="card-plain mt-2 table-card">
                      <CardBody>
                        {this.state.districts.length > 0 ?
                          <Table responsive>
                            <thead>
                              <tr>
                                <th className="text-center">#</th>
                                <th className="text-left">District Name</th>
                                <th className="text-left">District Admins</th>
                                <th className="text-left">Managers</th>
                                <th className="text-left">School Name</th>
                                <th className="text-center">School Count</th>
                                <th className="text-center">Used Count</th>
                                <th className="text-center">Total Count</th>
                                <th className="text-right">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                this.renderDistrictsData()
                              }
                            </tbody>
                          </Table>
                          :
                          <h4>No Data Available</h4>
                        }
                      </CardBody>
                    </Card>
                  </div>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

Districts.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps
)(Districts);