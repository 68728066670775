import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import DropdownScrollNavbar from "components/shared/DropdownScrollNavbar.js";
import constant from "../../shared/constant";
import DistrictManage from "./DistrictManage.js";
import ManagerModal from "./ManagerModal";
import SchoolObservations from "./SchoolObservations";
import Observations from "./Observations.js";
import SchoolManager from "./SchoolManagerDashboard";
import { SET_CURRENT_USER } from "../../actions/types.js";
import { SET_CURRENT_SCHOOL } from "../../actions/types.js";

// reactstrap components
import {
  Container,
  Row, 
  Modal,
  ModalFooter,
  Button,
  Col,
  Label
} from "reactstrap";

// core components
import Footer from "components/shared/Footer.js";
import { GAevent, GApageView } from "../../shared/gaUtils";
import _ from "lodash";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      obDataList: [],
      filteredObDataList: [],
      districtObservationCount: 0,
      districtView: false,
      schoolView: false,
      district: {},
      observationsInSchool: [],
      managerModal: false, 
      currentManager: {}, 
      modalSchools: [], 
      schoolManagerView: false, 
      schoolManagerTitle: "", 
      backWarning: false
    }
  }

  componentDidMount() {
    document.body.classList.add("home-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    GApageView("Home");
    GAevent('User', `User: ${this.props.auth.user.name} with user ID ${this.props.auth.user.id} logged in`);
    if (this.props.auth.user.role === constant['ROLE']['SCHOOL_ADMIN']) {
      this.getObservationsPerSchool(this.props.auth.user.school, this.props.auth.user.district._id);
    } else if (this.props.auth.user.role === constant['ROLE']['DISTRICT_ADMIN']) {
      this.getCurrentDistrict();
    } else if (this.props.auth.user.role === constant['ROLE']['MANAGER']) {
      this.getCurrentManager();
    } else {
      this.getObservationList();
    }
    if (this.props.auth.user.district && this.props.auth.user.role !== constant['ROLE']['TEACHER']) {
      this.getTotalObservationsCount();
    }
    // axios.post("/api/newobservations/updateDatabase")
    // .then((res) => console.log(res.data))
  }

  componentWillUnmount() {
    document.body.classList.remove("home-page");
    document.body.classList.remove("sidebar-collapse");
  }

  getCurrentDistrict() {
    this.setState({ district: this.props.auth.user.district })
    this.setState({ districtView: true, schoolView: false });
    
  }

  getCurrentManager() {
    let tempManager = this.props.auth.user.district.admins.filter((iterm) => iterm.email === this.props.auth.user.email)
    this.setState({ district: this.props.auth.user.district })
    this.setState({ schoolManagerView: true })
  }

  getTotalObservationsCount() {
    axios
      .post('/api/observations/districtObservationCount',
        {
          districtId: this.props.auth.user.district._id
        })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ districtObservationCount: res.data });
          if (res.data >= this.props.auth.user.district.totalObservations) {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className='confirm-dialog'>
                    <h1>Warning!</h1>
                    <p>You've reached the maximum observations available under contract, please contact your school admin for more options.</p>
                    <button className="btn default-back-color font-crm" onClick={onClose}>Okay</button>
                    <button
                      className="btn red-alert-color ml-2 font-crm"
                      onClick={() => {
                        onClose();
                        this.props.history.push('/contact-us');
                      }}
                    >
                      Contact
                    </button>
                  </div>
                );
              }
            });
          }
        }
      })
  }

  getObservationList = () => {
    axios
      .post('/api/observations',
        {
          userId: this.props.auth.user.id,
          email: this.props.auth.user.email,
          role: this.props.auth.user.role
        })
      .then((res) => {
        if (res.data) {
          this.setState({ obDataList: res.data, filteredObDataList: res.data }, () => {

          });
        }
      }).catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error('Please check your netwrok connection.');
        } else {
          toast.error(err.message || 'Error occured while retrieving observation data.');
        }
      });
  }

  createObservationRoom = () => {
    if (this.props.auth.user.district &&
      (this.state.districtObservationCount >= this.props.auth.user.district.totalObservations)) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='confirm-dialog'>
              <h1>Warning!</h1>
              <p>You've reached the maximum observations available under contract, please contact your school admin for more options.</p>
              <button className="btn default-back-color font-crm" onClick={onClose}>Okay</button>
              <button
                className="btn red-alert-color ml-2 font-crm"
                onClick={() => {
                  onClose();
                  this.props.history.push('/contact-us');
                }}
              >
                Contact
              </button>
            </div>
          );
        }
      });
      return;
    } else if (!this.props.auth.user.district && this.props.auth.user.role === constant['ROLE']['OBSERVER'] && localStorage.getItem('isSchoolUser') !== "true" && this.state.obDataList.length >= 1) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='confirm-dialog'>
              <h1>Warning!</h1>
              <p>You've reached the maximum observations available under contract, please contact your school admin for more options.</p>
              <button className="btn default-back-color font-crm" onClick={onClose}>Okay</button>
              <button
                className="btn red-alert-color ml-2 font-crm"
                onClick={() => {
                  onClose();
                  this.props.history.push('/contact-us');
                }}
              >
                Contact
              </button>
            </div>
          );
        }
      });
      return;
    }
    this.props.history.push('/classroom');
  }

  editObservation = (obData) => {
    localStorage.setItem(constant['OBSERVATION_ID'], obData._id);
    if (this.props.auth.user.role === constant['ROLE']['DISTRICT_ADMIN']) {
      this.props.history.push('/portfolio');
    } else if (this.props.auth.user.role === constant['ROLE']['TEACHER']) {
      this.props.history.push('/debrief');
    } else {
      this.props.history.push('/observe');
    }
  }

  searchObservation = (keyword) => {
    const searchTerm = keyword.toLowerCase();
    const filteredList = this.state.obDataList.filter((obData) => {
      return (
        obData.teacher && obData.teacher.toLowerCase().includes(searchTerm)
      )
    })

    this.setState(({ filteredObDataList: filteredList }))
  }

  getObservationsPerSchool(schoolId, districtId) {
    axios.post('/api/observations', {
      districtId: districtId,
      schoolId: schoolId,
      role: this.props.auth.user.role,
      observerId: this.props.auth.user.id
    }).then((res) => {
      const observationsByObservers = _.groupBy(res.data, 'observer._id');
      var observationsInSchool = [];
      _.mapKeys(observationsByObservers, (observations, observerId) => {
        const obsByTeachers = _.groupBy(observations, 'teacher');
        _.mapKeys(obsByTeachers, (observations, teacher) => {
          const dates = observations.map((obData) => {
            return {
              date: obData.dateModified,
              _id: obData._id,
              observerId: obData.observer._id
            };
          })
          observationsInSchool.push({
            teacher: teacher,
            total: observations.length,
            observer: observations[0].observer.firstName + ' ' + observations[0].observer.lastName,
            dates: dates,
            school: observations[0].school
          });
        });
      });
      this.setState({ observationsInSchool: observationsInSchool, districtView: false, schoolView: true });
    });
  }

  selectedSchool = (school) => {
    this.getObservationsPerSchool(school.id, this.props.auth.user.district._id);
  }

  editDistrict = (district) => {
    this.props.history.push(`/districts/${district._id}`);
  }

  editManager = (district, manager) => {
    this.setState({modalSchools: [...manager.schools]})
    this.setState({ managerModal: true, currentManager: manager })
  }

  deleteCurrentSchool = (school) => {
    let tempSchools = [...this.state.modalSchools];
    let resultSchools = tempSchools.filter(s => s !== school._id);
    this.setState({
      modalSchools: resultSchools,
    })
  }

  addOtherSchool = (school) => {
    let tempSchools = [...this.state.modalSchools];
    tempSchools.push(school._id)
    this.setState({
      modalSchools: tempSchools,
    })
  }

  closeDistrictModal = () => {
    // this.setState({ managerModal: false })
    this.setState({backWarning: true})
  }

  saveDistrictData = () => {
    let tempDistrict = this.state.district
    let adminIndex = tempDistrict.admins.findIndex((admin) => admin._id === this.state.currentManager._id)
    tempDistrict.admins[adminIndex].schools = [...this.state.modalSchools]
    this.setState({district: tempDistrict})
    this.setState({ managerModal: false })
    let extra = {
      method: "UPDATE_DISTRICT", 
      email: this.state.currentManager.email
    }
    axios.put(`/api/districts/${tempDistrict._id}`, { district: tempDistrict, extra: extra })
    .then((res) => {
      if (res.status === 200) {
        toast.info("Successfully Updated the District/School Admins.");
      } else {
        toast.info(res.data.message);
      }
    })
  }
  
  editSchoolAdmin = (school) => {
    const { dispatch } = this.props
    dispatch(setCurrentSchool(school));
    this.props.history.push('/schooladmin');
  }

  render() {
    return (
      <>
        <DropdownScrollNavbar location={this.props.location} />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="wrapper">
          <div className="home">
            <div className="panel">
              <Container fluid={true}>
                <Row>
                  <div className="mr-auto ml-auto col-lg-12">
                    {
                      this.state.schoolManagerView
                      ? <h3 className="title">Manager Main Dashboard</h3>
                      : this.state.districtView && !this.state.schoolView 
                      ? <h3 className="title">{this.props.auth.user.district.name}</h3>
                      : <h3 className="title">Welcome to Your Classroom180 Rubric Dashboard</h3>
                    }
                    <div className="observations" style={{paddingLeft: '50px', paddingRight: '50px'}}>
                      {!this.state.districtView && !this.state.schoolView && !this.state.schoolManagerView &&
                        < Observations
                          obDataList={this.state.filteredObDataList}
                          editObservation={this.editObservation}
                          createObservationRoom={this.createObservationRoom}
                          searchObservation={this.searchObservation}
                          resetObDataList = {this.getObservationList}
                        />
                      }
                      {this.state.districtView && !this.state.schoolView && !this.state.schoolManagerView &&
                        // new update for Tier 4
                        <DistrictManage 
                          district={this.state.district}
                          editDistrict={(district) => this.editDistrict(district)}
                          editManager={(district, manager) => this.editManager(district, manager)}
                        ></DistrictManage>
                      }
                      {!this.state.districtView && this.state.schoolView && !this.state.schoolManagerView &&
                        <SchoolObservations
                          observationsInSchool={this.state.observationsInSchool}
                          editObservation={this.editObservation}
                          createObservationRoom={this.createObservationRoom}
                        >
                        </SchoolObservations>
                      }
                      {
                        this.state.schoolManagerView &&
                        // Tier 3
                        <SchoolManager 
                          district={this.state.district}
                          editSchoolAdmin={(school) => this.editSchoolAdmin(school)}
                        />
                      }
                    </div>
                    {this.state.filteredObDataList.length === 0 && this.props.auth.user.role === constant['ROLE']['OBSERVER'] &&
                      <div className="row mt-5 features">
                        <div className="col-lg-1"></div>
                        <div className="col-lg-2">
                          <div className="icon-tools">
                            <div className="icon-image" style={{
                              backgroundImage: "url(" + require("assets/img/Icon01-book.png") + ")",
                            }} />
                            <h4 className="info-title">LEARN</h4>
                            <p>Read Classroom180 and memorize the domains, and components. Under-stand the process of the Classroom180 Rubric. To purchase Classroom180, click here.</p>
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="icon-tools">
                            <div className="icon-image" style={{
                              backgroundImage: "url(" + require("assets/img/Icon02-Observe.png") + ")",
                            }} />
                            <h4 className="info-title">OBSERVE</h4>
                            <p>Go into the classroom and carefully observe the classroom and gather the evidence from an objective and unbiased perspective.</p>
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="icon-tools">
                            <div className="icon-image" style={{
                              backgroundImage: "url(" + require("assets/img/Icon03-Consolidate.png") + ")",
                            }} />
                            <h4 className="info-title">CONSOLIDATE</h4>
                            <p>After the observation, consolidate the evidence by assigning your components and domains. Prepare for the debriefing step to come next.</p>
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="icon-tools">
                            <div className="icon-image" style={{
                              backgroundImage: "url(" + require("assets/img/Icon04-Debrief.png") + ")",
                            }} />
                            <h4 className="info-title">DEBRIEF</h4>
                            <p>Meet with the teacher for a collaborative post-observation debriefing. Gather more evidence with the teacher and determine levels of performance</p>
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="icon-tools">
                            <div className="icon-image" style={{
                              backgroundImage: "url(" + require("assets/img/Icon05-Portfolio.png") + ")",
                            }} />
                            <h4 className="info-title">PORTFOLIO</h4>
                            <p>Find your finalized Classroom180 Rubrics along with the Teacher Reflection and Growth Plans in the Portfolio.</p>
                          </div>
                        </div>
                        <div className="col-lg-1"></div>
                      </div>
                    }
                  </div>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
        {
          !this.state.schoolManagerView &&  
          <ManagerModal
            managerModal={this.state.managerModal}
            closeDistrictModal={() => this.closeDistrictModal()}
            manager={this.state.currentManager}
            district={this.state.district}
            modalSchools={this.state.modalSchools}
            deleteCurrentSchool={(school) => this.deleteCurrentSchool(school)}
            addOtherSchool={(school) => this.addOtherSchool(school)}
            saveDistrictData={() => this.saveDistrictData()}
          />
        }
        <Modal
          modalClassName="modal-confirm"
          isOpen={this.state.backWarning}
          // toggle={() => { this.setState({ email: '', name: '', type: 'School' }); this.props.closeDistrictModal() }}
        >
          <div className="modal-title">
            <h5 className="default-back-color" style={{ color: 'white', padding: '10px', textAlign: 'center' }}>Warning</h5>
          </div>
          <div className="modal-body just">
            <div>
              <Col className='text-center'>
                <Label className="font-crm font-crm-bold text-dark">
                  {`Your work has not been saved.`}
                </Label>
              </Col>
            </div>
          </div>
          <ModalFooter style={{ justifyContent: 'center' }}>
            <Button
              className="red-alert-color font-crm mr-2"
              onClick={() => { this.setState({ 
                backWarning: false,
                }) 
              }}
            >
              Cancel
            </Button>
            <Button
              className="default-back-color font-crm mr-2"
              onClick={() => { this.setState({ 
                backWarning: false,
                managerModal: false
                })
              }}
            >
              Confirm
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

Home.propTypes = {
  auth: PropTypes.object.isRequired,
};

export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
}

export const setCurrentSchool = school => {
  return {
    type: SET_CURRENT_SCHOOL,
    payload: school
  };
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps, 
)(Home);