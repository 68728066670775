import React, { Component } from "react";
import "../observe/Observe.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// reactstrap components
import {
  Table,
  Button
} from "reactstrap";
import constant from "../../shared/constant";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { SET_CURRENT_USER } from "../../actions/types";

class DistrictManage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      admins:[], 
      schools: []
    }
  }

  componentDidMount() {
    document.body.classList.add("user-control-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    this.setState({schools: [...this.props.district.schools]})
    console.log("object")
  }

  componentWillUnmount() {
    document.body.classList.remove("user-control-page");
    document.body.classList.remove("sidebar-collapse");
  }

  displayManagerEmails(managers, district) {

    let tempManagers = managers.filter((item) => item.archived === false).sort((a, b) => a.name > b.name ? 1 : -1)
    return tempManagers.map((manager, index) => {
      return (
        <div key={manager._id}>
          <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
            <div style={{width: '250px'}}>
              <Button
                className="btn-tooltip btn-icon mr-1 edit-btn"
                style={{backgroundColor: 'transparent', fontSize: '20px'}}
                size="sm"
                type="button"
                onClick={() => this.props.editManager(district, manager)}
              >
                <i className="now-ui-icons ui-2_settings-90" style={{color: '#55acee', fontSize: '15px'}}></i>
              </Button>
              <span style={{ fontWeight: 600, fontSize: '1.2em' }}>{manager.name}</span>
            </div>
            <span className="badge default-back-color" style={{margin: '10px', fontSize: '12px', padding: '10px'}}>{manager.email}</span>
            {/* <Button className="btn-neutral" style={{padding: '5px', fontSize: '20px'}} color="twitter" onClick={() => this.sendEmailToDA(manager.email, district._id)}>
              <i className="now-ui-icons ui-1_email-85"></i>
            </Button> */}
          </div>
          {manager.email === '' &&
            <p style={{ color: 'blue', fontSize: '0.9em', fontWeight: '500' }}>
              {`${process.env.REACT_APP_HOST_PATH}/register?district=${district._id}&role=${constant['ROLE']['DISTRICT_ADMIN']}&school=&email=${manager.email}`}
            </p>
          }
        </div>
      )
    })
  }

  sendEmailToDA(email, districtId) {
    axios
      .post('/api/districts/sendEmailToDA', { email: email, districtId: districtId })
      .then((res) => {
        toast.info(res.data && res.data.message);
      }).catch((err) => {
        console.log(err);
        toast.error(typeof err.response !== "undefined" ? err.response.data.message : err.message);
      });
  }

  displaySchools(schools, district) {
    let tempSchool = schools.filter((item) => item.archived === false).sort((a, b) => a.name > b.name ? 1 : -1)
    return tempSchool.map(school => {
      return (
        <div key={school._id}>
          <p style={{ display: 'block', text: 'left', color: 'black', fontWeight: '600' }}>
            <span style={{display: 'flex', alignItems: 'center'}}>
              {school.name}
            </span>
          </p>
        </div>
      )
    })
  }

  displaySchoolCount(schools) {
    let tempSchools = schools.filter((item) => item.archived === false).sort((a, b) => a.name > b.name ? 1 : -1)
    return tempSchools.map((school) => {
      let count = 0;
      if(school.users.length > 0) {
        school.users.forEach(user => {
          if(user.count) {
            count += user.count;
          }
        })
      }
      return (
        <div style={{display:'flex', alignItems: "center", justifyContent: 'center', gap: "10px", marginBottom: "15px"}} key={"schoolcound" + school._id}>
          <i onClick={() => this.handleMinus(school)} className="now-ui-icons ui-1_simple-delete" style={{backgroundColor: "#648292", padding: "5px", color: 'white', cursor: 'pointer'}}></i>
          <p style={{ display: 'block', text: 'left', color: 'black', fontWeight: '400', margin: '0px'  }}>
            <input value={school.count} onChange={(e) => this.enterCount(school, e)} style={{width: "50px", textAlign: 'center', borderRadius: '20px', fontSize: '15px'}} />
          </p>
          <i onClick={() => this.handlePlus(school)} className="now-ui-icons ui-1_simple-add" style={{backgroundColor: "#648292", padding: "5px", color: 'white', cursor: 'pointer'}}></i>
        </div>
      )
    });
  }

  displayUsedCount(schools) {
    let tempSchools = schools.filter((item) => item.archived === false).sort((a, b) => a.name > b.name ? 1 : -1)
    return tempSchools.map((school) => {
      let usedCount = 0;
      if (school.users.length > 0) {
        school.users.forEach(user => {
          if (user.count) {
            usedCount += user.count;
          }
        })
      }
      return (
        <div style={{display:'flex', alignItems: "center", justifyContent: 'center', gap: "10px"}} key={"schoolcound" + school._id}>
          <p style={{fontSize: '18px', fontWeight: '400', marginBottom: '19.5px'}}>{usedCount}</p>
        </div>
      )
    })
  }

  displayRemainCount(schools) {
    let tempSchools = schools.filter((item) => item.archived === false).sort((a, b) => a.name > b.name ? 1 : -1)
    return tempSchools.map((school) => {
      let usedCount = 0;
      if (school.users.length > 0) {
        school.users.forEach(user => {
          if (user.count) {
            usedCount += user.count;
          }
        })
      }
      return (
        <div style={{display:'flex', alignItems: "center", justifyContent: 'center', gap: "10px"}} key={"schoolcound" + school._id}>
          <p style={{fontSize: '18px', fontWeight: '400', marginBottom: '19.5px'}}>{school.count - usedCount}</p>
        </div>
      )
    })
  }

  saveDistrict(district) {
    const { dispatch } = this.props;
    let extra = {
      method: "UPDATE_DISTRICT", 
      email: this.props.auth.user.email
    }
    let tempUserData = {...this.props.auth.user}
    dispatch(setCurrentUser(tempUserData));
    axios.put(`/api/districts/${district._id}`, { district: district })
    .then((res) => {
      if (res.status === 200) {
        // toast.info("Successfully Updated the District/School Admins.");
      } else {
        // toast.info(res.data.message);
      }
    })
  }

  enterCount(school, e) {
    let index = this.state.schools.findIndex((item) => item._id === school._id)
    let tempSchools = [...this.state.schools]
    tempSchools[index].count = e.target.value === '' ? 0 : parseInt(e.target.value)
    let totalCounts = 0;
    tempSchools.forEach((item) => totalCounts += item.count)
    if (totalCounts > this.props.district.totalObservations) {
      toast.error("You have reached your limit!")
      tempSchools[index].count = 0
    }
    this.setState({schools: tempSchools})

    this.saveDistrict(this.props.district)
  }

  handleMinus(school) {
    let index = this.state.schools.findIndex((item) => item._id === school._id)
    let tempSchools = [...this.state.schools]
    // let tempSchools = this.state.schools.filter((item) => item.archived === false)
    let total = 0;
    for (let school of tempSchools) {
      if (school.users.length > 0) {
        for (let user of school.users) {
          total += user.count
        }
      }
    }

    if (tempSchools[index].count > total ) {
      tempSchools[index].count--
    } else {
      toast.error("The School Count cannot be less than the Used Count.")
    }
    this.setState({schools: tempSchools})

    this.saveDistrict(this.props.district)
  }

  handlePlus(school) {
    let index = this.state.schools.findIndex((item) => item._id === school._id)
    let tempSchools = [...this.state.schools]
    tempSchools[index].count++
    let totalCounts = 0;
    tempSchools.forEach((item) => totalCounts += item.count)
    if (totalCounts > this.props.district.totalObservations) {
      toast.error("You have reached your limit!")
      tempSchools[index].count -= 1
    }
    this.setState({schools: tempSchools})

    this.saveDistrict(this.props.district)
  }

  removeDistrict(district) {
    axios
      .delete(`/api/districts/${district._id}`)
      .then((res) => {
        toast.info(res.data.message);
        this.getDistricts();
      }).catch((err) => {
        console.log(err);
      });
  }

  displayAdmins(admins) {
    let tempAdmins = admins.sort((a, b) => a.name > b.name ? 1 : -1).filter((item) => item.archived === false)
    return tempAdmins.map((admin, index) => {
      return (
        <p key={admin.name + index} style={{ display: 'block', text: 'left', color: 'black', fontWeight: '600'}}>
          <span style={{ display: 'block', lineHeight: '15px' }}>{admin.name}</span>
          <span className="badge default-back-color mr-1" style={{padding: '10px', fontSize: '12px', marginTop: '5px'}}>{admin.email}</span>
        </p>
      )
    })
  }

  totalCounts(schools) {
    let tempSchools = this.state.schools.filter((item) => item.archived === false)
    let total = 0;
    tempSchools.forEach((item) => total += item.count)
    return <p style={{ display: 'block', text: 'left', color: 'black', fontWeight: '400' }}>{total}</p>
  }

  displayTotalUsedCount(schools) {
    let tempSchools = schools.filter((item) => item.archived === false)
    let total = 0;
    for (let school of tempSchools) {
      if (school.users.length > 0) {
        for (let user of school.users) {
          total += user.count
        }
      }
    }
    return <p style={{ display: 'block', text: 'left', color: 'black', fontWeight: '400' }}>{total}</p>
  }

  displayTotalRemainCount(schools) {
    let tempSchools = schools.filter((item) => item.archived === false)
    let total = 0;
    let totalUsed = 0
    for (let school of tempSchools) {
      total += school.count
      if (school.users.length > 0) {
        for (let user of school.users) {
          totalUsed += user.count
        }
      }
    }
    return <p style={{ display: 'block', text: 'left', color: 'black', fontWeight: '400' }}>{this.props.district.totalObservations - total}</p>
  }

  render() {
    return (
      <>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div style={{display: 'flex', justifyContent:'end', gap: '60px', padding: '12px 7px'}}>
          <div>
            <p style={{fontSize: '1.3em'}}>Total Count</p>
            <p style={{fontWeight: '600', marginTop: '25px', marginBottom: '0px'}}>{this.props.district.totalObservations}</p>
          </div>
          <div>
            <p style={{fontSize: '1.3em'}}>Actions</p>
              <Button
                className="btn-tooltip btn-icon mr-1"
                style={{backgroundColor: '#569959'}}
                size="sm"
                type="button"
                onClick={() => this.props.editDistrict(this.props.district)}
              >
                <i className="now-ui-icons ui-2_settings-90"></i>
              </Button>
          </div>
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th className="text-left">Admin</th>
              <th className="text-left">Managers</th>
              <th className="text-left">School Name</th>
              <th className="text-center">School Count</th>
              <th className="text-center">Used Count</th>
              <th className="text-right">Remaining Count</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td>{this.totalCounts(this.props.district.schools)}</td>
              <td className="text-center" style={{ verticalAlign: 'top' }}><p style={{fontWeight: 600}}>{this.displayTotalUsedCount(this.state.schools)}</p></td>
              <td className="text-center" style={{ verticalAlign: 'top' }}>{this.displayTotalRemainCount(this.state.schools)}</td>
            </tr>
            <tr>
              <td className="text-left" style={{ verticalAlign: 'top' }}>{this.displayAdmins(this.props.district.emails)}</td>
              <td className="text-left" style={{ verticalAlign: 'top' }}>{this.displayManagerEmails(this.props.district.admins, this.props.district)}</td>
              <td className="text-left" style={{ verticalAlign: 'top', display: 'flex', flexDirection: 'column', gap: '6px' }}>{this.displaySchools(this.state.schools, this.props.district)}</td>
              <td className="text-center" style={{ verticalAlign: 'top' }}>{this.displaySchoolCount(this.state.schools)}</td>
              <td className="text-center" style={{ verticalAlign: 'top' }}>{this.displayUsedCount(this.state.schools)}</td>
              <td className="text-center" style={{ verticalAlign: 'top' }}>{this.displayRemainCount(this.state.schools)}</td>
            </tr>
          </tbody>
        </Table>
      </>
    );
  }
}

DistrictManage.propTypes = {
  auth: PropTypes.object.isRequired,
};

export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(DistrictManage);
