import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import constant from "../../../shared/constant";
import { confirmAlert } from 'react-confirm-alert';

// reactstrap components
import {
  Row,
  Card,
  CardBody,
  Table,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Col,
  Button,
  Modal,
  ModalFooter,
  Label
} from "reactstrap";

// core components
import DropdownScrollNavbar from "components/shared/DropdownScrollNavbar.js";
import Footer from "components/shared/Footer.js";
import './UserControl.css';
import Container from "reactstrap/lib/Container";
import { find } from "lodash";
import { AiFillEye } from "react-icons/ai";
import { CloudWatchLogs } from "aws-sdk";

class Districts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      districts: [],
      district: {},
      currentSchool: null,
      modalSchool: null,
      currentDistrict: null,
      observations: [],
      searchText: '',
      districtModal: false,
      confirmModal: false,
      type: 'User',
      firstName: '',
      lastName: '',
      email: '',
      inserted: false,
      editedUser: [],
      editedAdmin: [],
      archivedStatus: false, 
      deleteUserModal: false, 
      deleteAdminModal: false,
      currentUser: {}, 
      currentAdmin: {}, 
      currentSchoolData: {}  // in Tier 3
    }
  }

  componentDidMount() {
    document.body.classList.add("user-control-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    if (this.props.auth.user.role === constant['ROLE']['MANAGER']) {
      this.getSchoolData()
    } else {
      this.getDistricts();
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("user-control-page");
    document.body.classList.remove("sidebar-collapse");
  }

  getTotalObservationsByDistricts(districts) {
    axios.post('/api/observations/totalObservationCountByDistricts')
      .then((res) => {
        let districtsObj = districts.map((district) => {
          const districtData = find(res.data, { district: district._id });
          return {
            ...district,
            count: districtData ? districtData.count : 0
          }
        });
        this.setState({ districts: districtsObj });
      });
  }

  getDistricts() {
    axios
      .get('/api/districts')
      .then((res) => {
        if (res.data && res.data.length > 0){
          let districts = [ ...res.data ];
          districts.forEach(district => {
            if (district.schools && district.schools.length > 0) {
              district.schools.forEach( async (school) => {
                if (school.admins && school.admins.length > 0) {
                  if (school.admins.filter(admin => admin.email.toLowerCase() === this.props.auth.user.email).length > 0) {
                    let observations = [];
                    await Promise.all(district.schools.map( async (school) => {
                      try {
                        const res = await axios
                        .post('/api/observations',
                          {
                            schoolId: school._id,
                            districtId: district._id,
                            role: "SchoolAdmin",
                            dashboard: true
                          })
                        if (res.data && res.data.length > 0) {
                          res.data.forEach( (observation) => {
                            observations.push(observation);
                          })
                        }
                      } catch {
                        console.log('Please check your netwrok connection.')
                      }
                    }))
                    this.setState({
                      currentSchool: {...school, admins: [...school.admins], users: [...school.users]},
                      modalSchool: {...school},
                      currentDistrict: district,
                      observations: observations,
                      editedUser: new Array(school.users.length).fill(false),
                      editedAdmin: new Array(school.admins.length).fill(false)
                    })
                    return;
                  }
                }
              })
            }
          })
          this.getTotalObservationsByDistricts(res.data);
        }
      }).catch((err) => {
        console.log(err);
      });
  }

  async getSchoolData() {
    axios.get('/api/districts')
    .then((res) => {
      let tempDistrict = res.data.filter((item) => item._id === this.props.auth.user.district._id)[0]
      let tempSchool = tempDistrict.schools.filter((item) => item._id === this.props.school._id)[0]
      tempSchool && this.setState({ 
        currentSchool: {...tempSchool, admins: [...tempSchool.admins], users: [...tempSchool.users]}, 
        currentDistrict: tempDistrict, 
        modalSchool: {...tempSchool}
      })
    })
    let observations = []
    await axios.post('/api/observations',{ schoolId: this.props.school._id, districtId: this.props.auth.user.district._id, role: constant['ROLE']['MANAGER'], dashboard: true})
    .then((res) => {
      if (res.data && res.data.length > 0) {
        res.data.forEach((observation) => {
          observations.push(observation)
        })
      }
      this.setState({ 
        observations: observations
      })
    })
  }

  editDistrict(district) {
    this.props.history.push(`/districts/${district._id}`);
  }

  editSchool(district, school) {
    this.props.history.push(`/school/${district._id}/${school._id}`);
  }

  onDeletedDistrictInformation = (district) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='confirm-dialog'>
            <h1>Are you sure?</h1>
            <p>Are you delete a District/School Information?  This can not be undone!</p>
            <button className="btn default-back-color font-crm" onClick={onClose}>Go Back</button>
            <button
              className="btn red-alert-color ml-2 font-crm"
              onClick={() => {
                onClose();
                this.removeDistrict(district);
              }}
            >
              Confirm
            </button>
          </div>
        );
      }
    });
  }

  removeDistrict(district) {
    axios
      .delete(`/api/districts/${district._id}`)
      .then((res) => {
        toast.info(res.data.message);
        this.getDistricts();
      }).catch((err) => {
        console.log(err);
      });
  }

  sendEmailToDA(email, districtId) {
    axios
      .post('/api/districts/sendEmailToDA', { email: email, districtId: districtId })
      .then((res) => {
        toast.info(res.data && res.data.message);
      }).catch((err) => {
        console.log(err);
        toast.error(typeof err.response !== "undefined" ? err.response.data.message : err.message);
      });
  }

  sendEmailToSA(email, districtId, schoolId) {
    axios
      .post('/api/districts/sendEmailToSA', { email: email, districtId: districtId, schoolId: schoolId })
      .then((res) => {
        toast.info(res.data && res.data.message);
      }).catch((err) => {
        console.log(err);
        toast.error(typeof err.response !== "undefined" ? err.response.data.message : err.message);
      });
  }

  displayAdminEmails(admins, district) {
    return admins.map((admin) => {
      return (
        <>
          <p style={{ display: 'block', text: 'left', color: 'black', fontWeight: '600' }}>
            <span style={{ display: 'block', lineHeight: '10px' }}>{admin.name}</span>
            <span className="badge default-back-color mr-1">{admin.email}</span>
            <Button className="btn-neutral" color="twitter" onClick={() => this.sendEmailToDA(admin.email, district._id)}>
              <i className="now-ui-icons ui-1_email-85"></i>
            </Button>
          </p>
          {admin.email === '' &&
            <p style={{ color: 'blue', fontSize: '0.9em', fontWeight: '500' }}>
              {`${process.env.REACT_APP_HOST_PATH}/register?district=${district._id}&role=${constant['ROLE']['DISTRICT_ADMIN']}&school=&email=${admin.email}`}
            </p>
          }
        </>
      )
    });
  }

  displaySchoolCount(schools, district) {
    return schools.map((school) => {
      let count = 0;
      if(school.users.length > 0) {
        school.users.forEach(user => {
          if(user.count) {
            count += user.count;
          }
        })
      }
      return (
        <>
          <p style={{ display: 'block', text: 'left', color: 'black', fontWeight: '400' }}>
            {count}
          </p>
        </>
      )
    });
  }

  displaySchools(schools, district) {
    return schools.map((school) => {
      return (
        <>
          <p style={{ display: 'block', text: 'left', color: 'black', fontWeight: '600' }}>
            <span>
              <Button
                className="btn-tooltip btn-icon mr-1 edit-btn"
                style={{backgroundColor: 'transparent'}}
                size="sm"
                type="button"
                onClick={() => this.editSchool(district, school)}
              >
                <i className="now-ui-icons ui-2_settings-90" style={{color: '#55acee', fontSize: '15px'}}></i>
              </Button>
              {school.name}
            </span>
          </p>
        </>
      )
    });
  }

  viewPortfolio(observation) {
    localStorage.setItem(constant['OBSERVATION_ID'], observation._id)
    this.props.history.push('/portfolio')
  }

  renderSchoolUser() {
    const moment = require('moment');
    let tempObservations = [ ...this.state.observations ];
    let tempUsers = [ ...this.state.currentSchool.users ];
    if (this.state.searchText !== "") {
      tempObservations = tempObservations.filter(observation => observation.teacher.toLowerCase().includes(this.state.searchText.toLowerCase()));
      let temp = this.state.currentSchool.users.filter(user => user.count > 0);
      let users = []
      temp.forEach(user => {
        if (tempObservations.filter(observation => observation.observer._id === user._id).length > 0) {
          users.push(user);
        }
      })
      tempUsers = [ ...users ];
      tempUsers.sort((a, b) => {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
    } else {
      let tempFill = this.state.currentSchool.users.filter(user => user.count > 0).sort((a, b) => {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
      let tempEmpty = this.state.currentSchool.users.filter(user => user.count === 0).sort((a, b) => {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
      tempUsers = tempFill.concat(tempEmpty);
    }
    tempObservations.sort((a, b) => {return (a.teacher > b.teacher) ? 1 : ((b.teacher > a.teacher) ? -1 : 0);});
    return tempUsers.map((user, index) => {
      if (user.archived === false ) {
        return (
          <tr key={"renderSchoolUser" + index}>
            <td className="text-left border-0" style={{ verticalAlign: 'top' }}>
              <p className="mt-2" style={{ display: 'block', text: 'left', color: 'black', fontWeight: '600' }}>
                <span className="pl-2" style={{ display: 'block', lineHeight: '15px' }}>{user.name}</span>
                <span className="badge default-back-color mr-1">{user.email}</span>
              </p>
            </td>
            <td className="text-center border-0 pt-4" style={{ verticalAlign: 'top' }}><p className="mt-2" style={{ fontSize: '1.2em', fontWeight: '400' }}>{user.count}</p></td>
            <td className="text-left border-0 pt-4" style={{ verticalAlign: 'top' }}>
              {tempObservations.filter(observation => observation.observer._id === user.userId).length > 0 && 
                tempObservations.filter(observation => observation.observer._id === user.userId).map(observation => {
                  return <p className="mt-2" style={{ fontSize: '1.2em', fontWeight: '400' }}>{observation.teacher}</p>
                })
              }
            </td>
            <td className="text-center border-0 pt-4" style={{ maxWidth: '130px', verticalAlign: 'top' }}>
              {/* {console.log("user.........", user)}
              {console.log("tempObservations.........", tempObservations)} */}
              {tempObservations.filter(observation => observation.observer._id === user.userId).length > 0 && 
                tempObservations.filter(observation => observation.observer._id === user.userId).map(observation => {
                  return <p className="mt-2" style={{ fontSize: '1.2em', fontWeight: '400' }}>{observation.grade}</p>
                })
              }
            </td>
            <td className="text-left border-0 pt-4" style={{ verticalAlign: 'top' }}>
              {tempObservations.filter(observation => observation.observer._id === user.userId).length > 0 && 
                tempObservations.filter(observation => observation.observer._id === user.userId).map(observation => {
                  return <p className="mt-2" style={{ fontSize: '1.2em', fontWeight: '400' }}>{moment(observation.dateModified).format('MM/DD/YYYY HH:MM A')}</p>
                })
              }
            </td>
            <td className="text-left border-0 pt-4" style={{ verticalAlign: 'top' }}>
              {tempObservations.filter(observation => observation.observer._id === user.userId).length > 0 && 
                tempObservations.filter(observation => observation.observer._id === user.userId).map(observation => {
                  return <p className="mt-2" style={{ fontSize: '1.2em', fontWeight: '400' }}>{observation.status === 2 ? "Completed" : "Not Completed"}</p>
                })
              }
            </td>
            <td className="text-left border-0 pt-4" style={{display: "flex", flexDirection: "column", gap: '13px'}}>
              {tempObservations.filter(observation => observation.observer._id === user.userId).length > 0 && 
                tempObservations.filter(observation => observation.observer._id === user.userId).map(observation => {
                  return <button className="mt-2" onClick={() => this.viewPortfolio(observation)} style={{backgroundColor: "#648292", border: "none", color: "white", borderRadius: "2px"}}>
                    <span style={{display: "flex", alignItems: "center", gap: "5px"}}><AiFillEye />View</span>
                  </button>
                })
              }
            </td>
          </tr>
        );
      }
      
    });
  }

  renderSchoolAdmin(status) {
    return this.state.currentSchool.admins.filter(item => item.archived === false).map((admin, index) => {
      const { name, email } = admin;
      return (
        <tr key={"renderSchool" + index}>
          <td className="text-left pb-0" style={{ verticalAlign: 'top', border: 'none' }}>
            <p className="d-block text-left text-dark mb-0" style={{ fontWeight: '600' }}>
              <span className="pl-2" style={{ display: 'block', lineHeight: '15px' }}>{name}</span>
              <span className="badge default-back-color mr-1">{email}</span>
            </p>
          </td>
        </tr>
      );
    });
  }

  createDistrictData() {
    this.props.history.push("/districts/create");
  }

  onChangeKeyword = (e) => {
    this.setState({
      searchText: e.target.value
    })
  }

  openModal = (type) => {
    this.setState({
      type: type,
      districtModal: true,
      firstName: '',
      lastName: '',
      email: ''
    })
  }

  onChangeName = (e, index) => {
    e.preventDefault()
    let tempUserName = []
    tempUserName[index] = e.target.value
    let tempModalSchool = {...this.state.modalSchool}
    tempModalSchool.users[index].name = e.target.value
    this.setState({
      modalSchool: {...tempModalSchool} 
    })
  }

  onChangeEmail = (e, index) => {
    e.preventDefault()
    let tempEmail = []
    tempEmail[index] = e.target.value
    
    let tempModalSchool = {...this.state.modalSchool}
    tempModalSchool.users[index].email = e.target.value
    this.setState({
      modalSchool: {...tempModalSchool}
    })
  }

  onChangeAdminName = (e, index) => {
    e.preventDefault()
    let tempAdminName = []
    tempAdminName[index] = e.target.value
    let tempModalSchool = {...this.state.modalSchool}
    tempModalSchool.admins[index].name = e.target.value
    this.setState({ modalSchool: {...tempModalSchool} })
  }

  onChangeAdminEmail = (e, index) => {
    e.preventDefault()
    let tempEmail = []
    tempEmail[index] = e.target.value
    let tempModalSchool = {...this.state.modalSchool}
    tempModalSchool.admins[index].email = e.target.value
    this.setState({ modalSchool: {...tempModalSchool} })
  }


  editSchoolAdmin = (index) => {
    if (this.state.type === "User") {
      let temp = new Array(this.state.editedUser.length).fill(false)
      temp[index] = true
      this.setState({ editedUser: [...temp] })
    } else {
      let temp = new Array(this.state.editedAdmin.length).fill(false)
      temp[index] = true
      this.setState({ editedAdmin: [...temp] })
    }
  }

  renderSchoolData = (status) => {
    let tempData = [];
    if(this.state.modalSchool) {
      if( this.state.type === 'User') {
        tempData = this.state.modalSchool.users.filter(item => item.archived === status)
      } else {
        tempData = this.state.modalSchool.admins.filter(item => item.archived === status);
      }
      return tempData.map((admin, index) => {
        return (
          <div key={"renderSchoolData" + index}>
            <Row style={{ alignItems: 'center' }}>
              <Button
                className="btn-icon btn-neutral btm btn-twitter"
                onClick={() => { this.editSchoolAdmin(index) }}
              >
                <i className="now-ui-icons ui-2_settings-90"></i>
              </Button>
              <Row style={{ marginLeft: '10px', width: '94%', justifyContent: "space-between", alignItems: 'center' }}>
                {
                  this.state.type === "User" && this.state.editedUser[index] 
                  ? <Input
                      onChange={(e) => this.onChangeName(e, index)}
                      value={admin.name}
                      placeholder="Enter name..."
                      style={{width: "30%"}}
                      type="text"
                      autoComplete="on"
                      onFocus={() => this.setState({ searchFocus: true })}
                      onBlur={() => this.setState({ searchFocus: false })}
                    />
                  : this.state.type === "School Admin" && this.state.editedAdmin[index]
                    ? <Input
                        onChange={(e) => this.onChangeAdminName(e, index)}
                        value={admin.name}
                        placeholder="Enter name..."
                        style={{width: "30%"}}
                        type="text"
                        autoComplete="on"
                        onFocus={() => this.setState({ searchFocus: true })}
                        onBlur={() => this.setState({ searchFocus: false })}
                      />
                    : <Col lg={3} style={{padding: "0px"}}>
                        <span className="font-crm text-dark" style={{wordWrap: 'break-word'}}>{admin.name}</span>
                      </Col>
                }
                {
                  this.state.type === "User" && this.state.editedUser[index]
                  ? <Input
                      onChange={(e) => this.onChangeEmail(e, index)}
                      value={admin.email}
                      placeholder="Enter email..."
                      style={{width: "45%"}}
                      type="email"
                      autoComplete="on"
                      onFocus={() => this.setState({ searchFocus: true })}
                      onBlur={() => this.setState({ searchFocus: false })}
                    />
                  : this.state.type === "School Admin" && this.state.editedAdmin[index]
                    ? <Input
                        onChange={(e) => this.onChangeAdminEmail(e, index)}
                        value={admin.email}
                        placeholder="Enter email..."
                        style={{width: "45%"}}
                        type="email"
                        autoComplete="on"
                        onFocus={() => this.setState({ searchFocus: true })}
                        onBlur={() => this.setState({ searchFocus: false })}
                      />
                    : <Col lg={4} style={{padding: "0px"}}>
                        <span className="font-crm text-dark" style={{wordWrap: 'break-word'}}>{admin.email}</span>
                      </Col>
                }
                {
                  this.state.type === "User" && <div className='text-center' style={{padding: "0px"}}>
                    <span className="font-crm">{admin.count ? admin.count : 0}</span>
                  </div>
                }
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <Button className="color-white border-0 p-2" style={{backgroundColor: "#cca923"}} 
                    onClick={() => this.state.type === "User" ? this.archiveBtn(this.state.modalSchool.users, admin) : this.archiveBtn(this.state.modalSchool.admins, admin)} >
                    <i className="fa fa-archive"></i>  {admin.archived ? "Archived": "Archive"}
                  </Button>
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <Button className="color-white border-0 p-2" style={{backgroundColor: "#b80838"}} 
                    onClick={() => this.state.type === "User" ? this.setState({ deleteUserModal: true, currentUser: admin }) : this.setState({ deleteAdminModal: true, currentAdmin: admin })} >
                    <i className="fa fa-trash"></i>  Delete
                  </Button>
                </div>
              </Row>
            </Row>
          </div>
        )
      });
    }
  }

  archiveBtn = (tempData, admin) => {
    if (this.state.type === "User") {
      let tempIndex = tempData.findIndex((item) => item._id === admin._id)
      let tempModalSchool = {...this.state.modalSchool}
      tempData[tempIndex].archived = !tempData[tempIndex].archived
      tempModalSchool.users = tempData
      this.setState({
        modalSchool: tempModalSchool
      })
      let tempCurrentDistrict = {...this.state.currentDistrict}
      let currentDistrictIndex = tempCurrentDistrict.schools.findIndex((item) => item._id === tempModalSchool._id)
      tempCurrentDistrict.schools[currentDistrictIndex] = tempModalSchool
    } else {
      let tempIndex = tempData.findIndex((item) => item._id === admin._id)
      let tempModalSchool = {...this.state.modalSchool}
      tempData[tempIndex].archived = !tempData[tempIndex].archived
      tempModalSchool.admins = tempData
      this.setState({
        modalSchool: tempModalSchool
      })
      let tempCurrentDistrict = {...this.state.currentDistrict}
      let currentDistrictIndex = tempCurrentDistrict.schools.findIndex((item) => item._id === tempModalSchool._id)
      tempCurrentDistrict.schools[currentDistrictIndex] = tempModalSchool
    }
    // axios.post('/api/districts/changeDB')
  }

  deleteConfirm = (tempData, admin) => {
    if (this.state.type === "User") {
      let tempIndex = tempData.findIndex((item) => item._id === admin._id)
      let tempModalSchool = {...this.state.modalSchool}
      tempData.splice(tempIndex, 1)
      tempModalSchool.users = tempData
      this.setState({ modalSchool: tempModalSchool })
      let tempCurrentDistrict = {...this.state.currentDistrict}
      let currentDistrictIndex = tempCurrentDistrict.schools.findIndex((item) => item._id === tempModalSchool._id)
      tempCurrentDistrict.schools[currentDistrictIndex] = tempModalSchool
      axios.post('/api/users/deleteUser', {email: admin.email})
        .then((res) => {
          toast.success(res.data.message)
        })
    } else {
      let tempIndex = tempData.findIndex((item) => item._id === admin._id)
      let tempModalSchool = {...this.state.modalSchool}
      tempData.splice(tempIndex, 1)
      tempModalSchool.admins = tempData
      this.setState({ modalSchool: tempModalSchool })
      let tempCurrentDistrict = {...this.state.currentDistrict}
      let currentDistrictIndex = tempCurrentDistrict.schools.findIndex((item) => item._id === tempModalSchool._id)
      tempCurrentDistrict.schools[currentDistrictIndex] = tempModalSchool
      axios.post('/api/users/deleteUser', {email: admin.email})
        .then((res) => {
          toast.success(res.data.message)
        })
    }
  }

  addSchoolData = async () => {
    if (this.state.email === '') {
      toast.info('Email can not be empty');
      return;
    }
    if (this.state.firstName === '' || this.state.lastName === '') {
      toast.info('Name can not be empty');
      return;
    }
    let tempSchool = {...this.state.modalSchool};

    await axios.post(`/api/users/getByEmail`, {email: this.state.email})
    .then((res) => {
      if( this.state.type === 'User') {
        let user = [{ 
          name: this.state.firstName + ' ' + this.state.lastName, 
          email: this.state.email, 
          count: 0, 
          archived: false, 
          userId: res.data === "" ? null : res.data._id
        }];
        tempSchool.users = user.concat(tempSchool.users);
      } else {
        let admin = [{ 
          name: this.state.firstName + ' ' + this.state.lastName, 
          email: this.state.email, 
          archived: false 
        }];
        tempSchool.admins = admin.concat(tempSchool.admins);
      }
    })
    
    let tempCurrentDistrict = {...this.state.currentDistrict}
    let currentDistrictIndex = tempCurrentDistrict.schools.findIndex((item) => item._id === tempSchool._id)
    tempCurrentDistrict.schools[currentDistrictIndex] = tempSchool;
    this.setState({ currentDistrict: tempCurrentDistrict })
    let tempCurrentSchool = {...this.state.currentSchool}
    tempCurrentSchool.admins = tempSchool.admins
    this.setState({
      modalSchool: tempSchool,
      firstName: '',
      lastName: '',
      email: '',
      inserted: true
    })
  }

  onChangeSchoolData = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  }

  onSave = () => {
    axios
      .put(`/api/districts/${this.state.currentDistrict._id}`, { district: this.state.currentDistrict, type: this.state.type })
      .then((res) => {
        if (res.status === 200) {
          toast.info("Successfully Updated the District/School Admins.");
          this.setState({
            currentSchool: {...this.state.modalSchool, admins: [...this.state.modalSchool.admins], users: [...this.state.modalSchool.users]},
            districtModal: false,
            firstName: '',
            lastName: '',
            email: '',
            inserted: false,
            editedAdmin: [],
            editedUser: []
          })
        } else {
          toast.info(res.data.message);
        }
      }).catch((err) => {
        if (err.response) {
          console.log(err.response);
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error('Please check your netwrok connection.');
        } else {
          toast.error(err.message || 'Error occured while updating District data.');
        }
      });
  }

  districtModalBack = () => {
    this.setState({ 
      districtModal: false,
      editedAdmin: [],
      editedUser: []
    })
  }

  displayUsedCount = () => {
    let tempUsers = [...this.state.currentSchool.users];
    let tempCount = 0;
    for (let user of tempUsers) {
      tempCount += user.count
    }
    return tempCount
  }

  displayRemainCount = () => {
    let tempUsers = [...this.state.currentSchool.users];
    let tempCount = 0;
    for (let user of tempUsers) {
      tempCount += user.count
    }
    return this.state.currentSchool.count - tempCount
  }

  render() {
    return (
      <>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <DropdownScrollNavbar location={this.props.location} />
        <div className="wrapper">
          <div className="home">
            <div className="panel">
              <Container fluid={true}>
                <Row>
                  <div className="mr-auto ml-auto col-lg-12">
                    <h3 className="title">School Admin Dashboard</h3>
                  </div>
                </Row>
                {this.state.currentSchool && 
                  <Row className='mb-5'>
                    <div style={{marginLeft: '80px'}}>
                      <h4 className="text-left school-name">{this.state.currentSchool.name}</h4>
                      <div className="d-flex m-0 mt-4">
                        <div className="text-center mr-5">
                          <h5 className="mb-1">School Count</h5>
                          <h5>{this.state.currentSchool.count}</h5>
                        </div>
                        <div className="text-center mr-5">
                          <h5 className="mb-1">Used Count</h5>
                          <h5>
                            {this.displayUsedCount()}
                          </h5>
                        </div>
                        <div className="text-center mr-5">
                          <h5 className="mb-1">Remaining Count</h5>
                          <h5>{this.displayRemainCount()}</h5>
                        </div>
                      </div>
                    </div>
                  </Row>
                }
                <Row>
                  <Card className="card-plain mt-2 table-card">
                    <CardBody>
                      <Table responsive>
                        <thead>
                          <tr className="tr-border-bottom">
                            <th className="text-left pb-0">School Admin</th>
                            <th className="text-right pb-1" width='100'>
                              <Button
                                className="btn-tooltip btn-icon mr-1"
                                style={{backgroundColor: '#569959'}}
                                size="sm"
                                type="button"
                                onClick={e => this.openModal('School Admin')}
                              >
                                <i className="now-ui-icons ui-2_settings-90"></i>
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.currentSchool && this.state.currentSchool.admins.length > 0 ? this.renderSchoolAdmin(this.state.archivedStatus) : <h4>No Data Available</h4>
                          }
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Row>
                {this.state.currentSchool && 
                  <Row className="table-card">
                    <Col lg="9"></Col>
                    <Col lg="3">
                      <InputGroup
                        className={this.state.searchFocus ? "input-group-focus" : ""}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons ui-1_zoom-bold"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={this.onChangeKeyword}
                          value={this.state.searchText}
                          placeholder="Enter Teacher Name"
                          id="keyword"
                          type="text"
                          autoComplete="on"
                          onFocus={() => this.setState({ searchFocus: true })}
                          onBlur={() => this.setState({ searchFocus: false })}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                }
                <Row>
                  <Card className="card-plain mt-2 table-card">
                    <CardBody>
                      <Table responsive>
                        <thead>
                          <tr className="tr-border-bottom">
                            <th className="text-left">Users</th>
                            <th className="text-center">Count</th>
                            <th className="text-left">Teacher</th>
                            <th className="text-center">Grade</th>
                            <th className="text-left" width='230'>Last Completed</th>
                            <th className="text-left" width='180'>Status</th>
                            <th className="text-left" width='100'>View</th>
                            <th className="text-right pb-1" width='80'>
                              <Button
                                className="btn-tooltip btn-icon mr-1"
                                style={{backgroundColor: '#569959'}}
                                size="sm"
                                type="button"
                                onClick={e => this.openModal('User')}
                              >
                                <i className="now-ui-icons ui-2_settings-90"></i>
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.currentSchool && this.state.currentSchool.users.length > 0 ? this.renderSchoolUser() : <h4>No Data Available</h4>
                          }
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
        <Modal
          modalClassName="modal-district"
          isOpen={this.state.districtModal}
          // toggle={() => { this.setState({ email: '', name: '', type: 'School' }); this.props.closeDistrictModal() }}
        >
          <div className="modal-title">
            <h5 className="default-back-color">
                {this.state.type} Information
            </h5>
          </div>
          <div className="modal-body just">
            <Col className='text-left'>
              <Label className="font-crm font-crm-bold text-dark">{this.state.type}</Label>
              <Row style={{ alignItems: 'flex-end', marginRight: 0, justifyContent: 'space-between' }}>
                <Col xs='3' className='px-1'>
                  <InputGroup
                    className={this.state.adminFirstNameFocus ? "input-group-focus" : ""}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons business_bank"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.onChangeSchoolData}
                      value={this.state.firstName}
                      placeholder={`${this.state.type} First Name`}
                      id="firstName"
                      type="text"
                      autoComplete="off"
                      required
                      onFocus={() => this.setState({ adminFirstNameFocus: true })}
                      onBlur={() => this.setState({ adminFirstNameFocus: false })}
                    />
                  </InputGroup>
                </Col>
                <Col xs='3' className='px-1'>
                  <InputGroup
                    className={this.state.adminLastNameFocus ? "input-group-focus" : ""}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons business_bank"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.onChangeSchoolData}
                      value={this.state.lastName}
                      placeholder={`${this.state.type} Last Name`}
                      id="lastName"
                      type="text"
                      autoComplete="off"
                      required
                      onFocus={() => this.setState({ adminLastNameFocus: true })}
                      onBlur={() => this.setState({ adminLastNameFocus: false })}
                    />
                  </InputGroup>
                </Col>
                <Col xs='5' className='px-1'>
                  <InputGroup
                    className={this.state.districtAdminFocus ? "input-group-focus" : ""}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons ui-1_email-85"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.onChangeSchoolData}
                      value={this.state.email}
                      placeholder={`${this.state.type} Email`}
                      id="email"
                      type="text"
                      autoComplete="off"
                      onFocus={() => this.setState({ districtAdminFocus: true })}
                      onBlur={() => this.setState({ districtAdminFocus: false })}
                    />
                  </InputGroup>
                </Col>
                <Button className="btn btn-default default-back-color" onClick={() => { this.addSchoolData() }}>
                  <i className="now-ui-icons ui-1_simple-add"></i>
                </Button>
              </Row>
              <Row style={{ alignItems: 'center', marginLeft: '10px', marginTop: '10px'}}>
                <Button
                  className="btn-icon btn-neutral btm btn-twitter mr-1"
                  style={{opacity: 0}}
                >
                  <i className="now-ui-icons ui-2_settings-90"></i>
                </Button>
                <Row style={{ marginLeft: '-7px', width: '95%' }}>
                  <Col lg={3}>
                  </Col>
                  <Col lg={4}>
                  </Col>
                  {
                    this.state.type === "User" && <Col lg={3} className='text-center'>
                      <span className="font-crm" style={{fontWeight: '600'}}>Used Count</span>
                    </Col>
                  }
                  <Col className="archivedStatusbutton">
                    <Button
                      className="color-white border-0" style={{backgroundColor: "#cca923"}}
                      onClick={() => { this.setState({archivedStatus: !this.state.archivedStatus}) }}
                    >
                      {this.state.archivedStatus ? "My Data" : "Archived"}
                    </Button>
                  </Col>
                </Row>
              </Row>
              {
                this.renderSchoolData(this.state.archivedStatus)
              }
            </Col>
          </div>
          <ModalFooter style={{ justifyContent: 'center' }}>
            <Button
              className="red-alert-color font-crm mr-2"
              onClick={() => { this.state.inserted === true ? this.setState({ confirmModal: true }) : this.districtModalBack() }}
            >
              Back
            </Button>
            <Button
              className="default-back-color font-crm mr-2"
              onClick={() => this.onSave()}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          modalClassName="modal-confirm"
          isOpen={this.state.confirmModal}
          // toggle={() => { this.setState({ email: '', name: '', type: 'School' }); this.props.closeDistrictModal() }}
        >
          <div className="modal-title">
            <h5 className="default-back-color" style={{height: '38px'}}>
            </h5>
          </div>
          <div className="modal-body just">
            <div>
              <Col className='text-left'>
                <Label className="font-crm font-crm-bold text-dark">
                  Your data will be lost!<br/>Are you sure that back to dashboard?
                </Label>
              </Col>
            </div>
          </div>
          <ModalFooter style={{ justifyContent: 'center' }}>
            <Button
              className="red-alert-color font-crm mr-2"
              onClick={() => { this.setState({ 
                  confirmModal: false,
                  districtModal: false,
                  modalSchool: {...this.state.currentSchool, admins: [...this.state.currentSchool.admins], users: [...this.state.currentSchool.users],},
                  inserted: false
                }) 
              }}
            >
              Yes
            </Button>
            <Button
              className="default-back-color font-crm mr-2"
              onClick={() => this.setState({confirmModal: false})}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          modalClassName="modal-confirm"
          isOpen={this.state.deleteUserModal}
          // toggle={() => { this.setState({ email: '', name: '', type: 'School' }); this.props.closeDistrictModal() }}
        >
          <div className="modal-title">
            <h5 className="default-back-color" style={{ color: 'white', padding: '10px', textAlign: 'center' }}>Warning
            </h5>
          </div>
          <div className="modal-body just">
            <div>
              <Col className='text-left'>
                <Label className="font-crm font-crm-bold text-dark">
                  {
                    this.state.currentUser.count > 0 
                    ? "This User cannot be deleted. User has data."
                    : "Are you sure you want to permanently delete this User?  This can not be undone."
                  }
                  
                </Label>
              </Col>
            </div>
          </div>
          <ModalFooter style={{ justifyContent: 'center' }}>
            {
              this.state.currentUser.count > 0 ? <Button
                className="red-alert-color font-crm mr-2"
                onClick={() => { this.setState({ 
                    deleteUserModal: false,
                  }) 
                }}
              >
                Okay
              </Button>
              : <div style={{gap: '20px', display: 'flex'}}>
                  <Button
                    className="red-alert-color font-crm mr-2"
                    onClick={() => { this.setState({ 
                        deleteUserModal: false,
                      }) 
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="default-back-color font-crm mr-2"
                    onClick={() => { this.setState({ 
                        deleteUserModal: false,
                      })
                      this.deleteConfirm(this.state.modalSchool.users, this.state.currentUser)
                    }}
                  >
                    Confirm
                  </Button>
              </div>
            }
          </ModalFooter>
        </Modal>
        <Modal
          modalClassName="modal-confirm"
          isOpen={this.state.deleteAdminModal}
          // toggle={() => { this.setState({ email: '', name: '', type: 'School' }); this.props.closeDistrictModal() }}
        >
          <div className="modal-title">
            <h5 className="default-back-color" style={{ color: 'white', padding: '10px', textAlign: 'center' }}>Warning
            </h5>
          </div>
          <div className="modal-body just">
            <div>
              <Col className='text-left'>
                <Label className="font-crm font-crm-bold text-dark">
                  {
                    this.state.currentUser.count > 0 
                    ? "This Admin cannot be deleted. User has data."
                    : "Are you sure you want to permanently delete this User?  This can not be undone."
                  }
                  
                </Label>
              </Col>
            </div>
          </div>
          <ModalFooter style={{ justifyContent: 'center' }}>
            {
              <div style={{gap: '20px', display: 'flex'}}>
                <Button
                  className="red-alert-color font-crm mr-2"
                  onClick={() => { this.setState({ 
                      deleteAdminModal: false,
                    }) 
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="default-back-color font-crm mr-2"
                  onClick={() => { this.setState({ 
                    deleteAdminModal: false,
                    })
                    this.deleteConfirm(this.state.modalSchool.admins, this.state.currentAdmin)
                  }}
                >
                  Confirm
                </Button>
            </div>
            }
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

Districts.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  school: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  school: state.school
});

export default connect(
  mapStateToProps
)(Districts);