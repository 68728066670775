import React, { Component } from "react";
import "../observe/Observe.css";
import "./SchoolManager.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// reactstrap components
import {
  Table,
  Button
} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import { AiFillEye } from "react-icons/ai";

class SchoolManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      manager: {}, 
      schools: [], 
    }
  }

  componentDidMount() {
    document.body.classList.add("user-control-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    this.filterData()
  }

  componentWillUnmount() {
    document.body.classList.remove("user-control-page");
    document.body.classList.remove("sidebar-collapse");
  }

  filterData() {
    let tempManager = this.props.district.admins.filter((admin) => admin.email.toLowerCase() === this.props.auth.user.email)[0]
    let tempSchools = []
    if (tempManager.schools) {
      for (let i = 0; i < tempManager.schools.length; i++) {
        for (let j = 0; j < this.props.district.schools.length; j++) {
          if (tempManager.schools[i] === this.props.district.schools[j]._id) tempSchools.push(this.props.district.schools[j])
        }
      }
    }
    this.setState({
      manager: tempManager, 
      schools: [...tempSchools]
    })
  }

  displayManager(manager) {
    return (
      <div style={{ textAlign: 'left', color: 'black', fontWeight: '600', display: 'flex', alignItems: 'center', gap: "20px" }}>
        <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
          <span style={{ display: 'block', lineHeight: '10px' }}>{manager.name}</span>
        </div>
        <span className="badge default-back-color" style={{fontSize: "14px", margin: "0px"}}>{manager.email}</span>
      </div>
    )
  }

  displaySchools(schools) {
    let tempSchools = schools.sort((a, b) => a.name > b.name ? 1 : -1)
    return tempSchools.map(school => {
      return (
        <div key={school._id}>
          {/* <p style={{ display: 'flex', alignItems:'center', text: 'left', color: 'black', fontWeight: '600' }}> */}
          <p className="school">
            <Button
              className="btn-tooltip btn-icon mr-1 edit-btn"
              style={{backgroundColor: 'transparent'}}
              size="sm"
              type="button"
              // onClick={() => this.props.editManager(district, manager)}
              onClick={() => this.props.editSchoolAdmin(school)}
            >
              {/* <i className="now-ui-icons ui-2_settings-90" style={{color: '#55acee', fontSize: '15px'}}></i> */}
              {/* <i className="fa fa-eye"></i> */}
              <AiFillEye color="#55acee" size={20} />
            </Button>
            <span style={{display: 'flex', alignItems: 'center'}}>
              {school.name}
            </span>
          </p>
        </div>
      )
    })
  }

  displayUsedCount(schools) {
    let tempSchools = schools.sort((a, b) => a.name > b.name ? 1 : -1)
    return tempSchools.map((school) => {
      let count = 0;
      if(school.users.length > 0) {
        school.users.forEach(user => {
          if(user.count) {
            count += user.count;
          }
        })
      }
      return (
        <div key={"schoolcound" + school._id}>
          <p className="count"  style={{marginBottom: "25px"}}>
            {count}
          </p>
        </div>
      )
    });
  }

  displayTotalCount(schools) {
    let tempSchools = schools.sort((a, b) => a.name > b.name ? 1 : -1)
    return tempSchools.map((school) => {
      return (
        <p className="count"  style={{marginBottom: "25px"}}>{school.count}</p>
      )
    });
  }

  displayRemaingCount(schools) {
    let tempSchools = schools.sort((a, b) => a.name > b.name ? 1 : -1)
    return tempSchools.map((school) => {
      let count = 0;
      if(school.users.length > 0) {
        school.users.forEach(user => {
          if(user.count) {
            count += user.count;
          }
        })
      }
      return (
        <div key={"remaingCounts" + school._id}>
          <p className="count" style={{marginBottom: "25px"}}>
            {school.count - count}
          </p>
        </div>
      )
    });
  }

  render() {
    return (
      <>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Table responsive>
          <thead>
            <tr>
              <th className="text-left">Manager</th>
              <th className="text-left">School Name</th>
              <th className="text-left">School Count</th>
              <th className="text-left">Used Count</th>
              <th className="text-left">Remaining Count</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-left" style={{ verticalAlign: 'top' }}>{this.displayManager(this.state.manager)}</td>
              <td className="text-left displaySchools">{this.displaySchools(this.state.schools)}</td>
              <td className="text-center">{this.displayTotalCount(this.state.schools)}</td>
              <td className="text-center">{this.displayUsedCount(this.state.schools)}</td>
              <td className="text-center">{this.displayRemaingCount(this.state.schools)}</td>
            </tr>
          </tbody>
        </Table>
      </>
    );
  }
}

SchoolManager.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(SchoolManager);
