import React, { Component } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Card,
  CardBody,
  Button,
  Col,
  Label,
  Row,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ButtonGroup,
  Container, 
  Modal,
  ModalFooter,
} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';
import CardFooter from "reactstrap/lib/CardFooter";
import DropdownScrollNavbar from "components/shared/DropdownScrollNavbar.js";
import CardHeader from "reactstrap/lib/CardHeader";
import Footer from "components/shared/Footer.js";
import DistrictModal from "../user-control/DistrictModal";
import constant from "../../../shared/constant";
import { SET_CURRENT_USER } from "../../../actions/types";

class District extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'create',
      district: {
        name: '',
        admins: [],
        schools: [],
        totalObservations: 0,
        emails: []
      },
      schoolName: '',
      schoolEmail: '',
      districtName: '',
      email: '',
      districtModal: false,
      editType: 'School',
      editEmail: '',
      editName: '',
      editIndex: -1, 
      archivedStatus: false, 
      userType: '', 
      adminEmails: [], 
      adminName: '',
      adminEmail: '', 
      backWarningModal: false
    }
  }

  componentDidMount() {
    document.body.classList.add("user-control-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    if (this.props.auth.user.role !== constant['ROLE']['SUPER_ADMIN'] && this.props.auth.user.role !== constant['ROLE']['DISTRICT_ADMIN']) {
      this.props.history.push('/');
    } else {
      if (this.props.match.params.id) {
        this.setState({ type: 'edit' });
        this.getDistrictDetail(this.props.match.params.id);
      }
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("user-control-page");
    document.body.classList.remove("sidebar-collapse");
  }

  getDistrictDetail = async (districtId) => {
    let currentDistrict = {}
    let adminEmails = []
    await axios.get(`/api/districts/${districtId}`)
      .then(async (res) => {
        this.setState({ district: res.data });
        currentDistrict = {...res.data}
      }).catch((err) => {
        console.log(err);
      });
    await axios.post('/api/users/emails', {emails: currentDistrict.emails})
    .then((res) => this.setState({adminEmails: res.data}))

  }

  onChange = (e) => {
    if (e.target.id === 'schoolName' || e.target.id === 'districtName' || e.target.id === 'email') {
      this.setState({ [e.target.id]: e.target.value });
    } else {
      let districtObj = this.state.district;
      districtObj[e.target.id] = e.target.value;
      this.setState({ district: districtObj });
    }
  }

  saveDistrict(district, extra) {
    const { dispatch } = this.props; 
    if (this.state.type === 'edit') {
      axios
        .put(`/api/districts/${district._id}`, { district: district, extra: extra })
        .then((res) => {
          if (res.status === 200) {
            let tempUserData = {...this.props.auth.user}
            tempUserData.district = {...res.data}
            dispatch(setCurrentUser(tempUserData));
            toast.info("Successfully Updated the District/School Admins.");
          } else {
            toast.info(res.data.message);
          }
        }).catch((err) => {
          if (err.response) {
            console.log(err.response);
            toast.error(err.response.data.message);
          } else if (err.request) {
            toast.error('Please check your netwrok connection.');
          } else {
            toast.error(err.message || 'Error occured while updating District data.');
          }
        });
    } else {
      axios
        .post('/api/districts', { district })
        .then((res) => {
          if (res.status === 200) {
            toast.info("Successfully Created the District/School Admins.");
          } else {
            toast.info(res.data.message);
          }
        }).catch((err) => {
          if (err.response) {
            console.log(err.response);
            toast.error(err.response.data.message);
          } else if (err.request) {
            toast.error('Please check your netwrok connection.');
          } else {
            toast.error(err.message || 'Error occured while creating District data.');
          }
        });
    }
  }

  updateDistrictSchoolData = (data) => {
    this.setState({ districtModal: false, editIndex: -1, editName: '', editEmail: '', editType: 'School' });
    if (data.type === 'School') {
      let tempDistrict = {...this.state.district}
      let index = tempDistrict.schools.findIndex((item) => item._id === data.email._id)

      let school = tempDistrict.schools[index]
      let emailUpdated = false;
      if (school.name !== data.name && school.email !== data.email.email) {
        emailUpdated = true;
      } else if (school.name !== data.name || school.email === data.email.email) {
        emailUpdated = false;
      } else if (school.name === data.name || school.email !== data.email.email) {
        emailUpdated = true;
      } else {
        return;
      }
      if (data.status === '') {
        tempDistrict.schools[index] = data.email
      } else if (data.status === 'delete') {
        tempDistrict.schools.splice(index, 1)
      }
      this.saveDistrict(tempDistrict, { method: 'UPDATE_SCHOOL', email: data.email, emailUpdated: emailUpdated });
    } else {
      let admins = this.state.district.admins;
      let emails = this.state.district.emails;
      let adminIndex = admins.findIndex((item) => item._id === data.email._id)
      let emailIndex = emails.findIndex((item) => item._id === data.email._id)
      if (data.status === '') {
        admins[adminIndex] = data.email
        emails[emailIndex] = data.email
        this.setState({...this.state.district, admins: admins, emails: emails })
        let tempDistrict = {...this.state.district, admins: admins}
        this.saveDistrict(tempDistrict, { method: 'UPDATE_DISTRICT', email: data.email.email })
      } else if (data.status === 'delete') {
        admins.splice(adminIndex, 1)
        emails.splice(emailIndex, 1)
        let tempDistrict = {...this.state.district, admins: admins, emails: emails}
        this.saveDistrict(tempDistrict, { method: 'DELETE_DISTRICT', email: data.email.email })
        axios.post('/api/users/deleteUser', {email: data.email.email})
        .then((res) => {
          toast.success(res.data.message)
        })
      }
    }
  }

  updateTotalUserCount = (isPlus) => {
    let districtObj = this.state.district;
    let count = districtObj.totalObservations;
    if (!isPlus) {
      if (count <= 0) return;
      count = count - 1;
    } else {
      count = count + 1;
    }
    districtObj['totalObservations'] = count;
    this.setState({ district: districtObj });
  }

  addSchoolData = () => {
    if (this.state.schoolName === '') {
      toast.info('School Name can not be empty');
      return;
    }
    let schools = this.state.district.schools;
    let count = 0
    for(let tempSchool of schools) {
      if (tempSchool.name.replace(/\s/g, '').toLowerCase() === this.state.schoolName.replace(/\s/g, '').toLowerCase()) count++;
    }
    if (count === 0 ) {
      schools.push({
        name: this.state.schoolName,
        email: this.state.schoolEmail,
        archived: false
      });
    } else {
      toast.error('School is already created!');
      this.setState({schoolName: ''})
      return;
    }
    if (this.state.type === 'edit') {
      let district = {
        ...this.state.district,
        schools: this.state.district.schools,
      }
      this.saveDistrict(district, { method: 'UPDATE_SCHOOL', email: this.state.schoolEmail, emailUpdated: true });
    }
    this.setState({ schoolName: '', schoolEmail: '' });
  }

  addDistrictData = () => {
    if (this.state.email === '') {
      toast.info(`${this.props.auth.user.role === constant['ROLE']['DISTRICT_ADMIN'] ? 'Manager Email' : 'District Admin Email'} can not be empty.`);
      return;
    }
    if (this.state.districtName === '') {
      toast.info(`${this.props.auth.user.role === constant['ROLE']['DISTRICT_ADMIN'] ? 'Manager Name' : 'District Admin Name'} can not be empty.`);
      return;
    }
    let admin = {email: this.state.email, name: this.state.districtName, archived: false};
    let tempDistrict = {...this.state.district};
    if (this.props.auth.user.role === constant['ROLE']['SUPER_ADMIN']) {
      tempDistrict.emails.push(admin);
    } else {
      tempDistrict.admins.push(admin);
    }
    this.setState({
      district: tempDistrict
    })
    if (this.state.type === 'edit') {
      let district = {
        ...this.state.district,
        emails: this.state.district.emails,
      }
      this.saveDistrict(district, { method: 'UPDATE_DISTRICT', email: this.state.email });
    }
    this.setState({ email: '', districtName: '' });
  }

  addAdminData = () => {
    if (this.state.adminEmail === '') {
      toast.info('Admin Email can not be empty');
      return;
    }
    if (this.state.adminName === '') {
      toast.info('Admin Name can not be empty');
      return;
    }
    let admin = {email: this.state.adminEmail, name: this.state.adminName, archived: false}
    let tempDistrict = {...this.state.district}
    tempDistrict.emails.push(admin)
    this.setState({ district: tempDistrict })
    this.saveDistrict(tempDistrict, {method: "UPDATE_DISTRICT", email: this.state.adminEmail})
    this.setState({adminEmail: '', adminName: ''})
  }

  removeSchoolData = (school, index) => {
    let schools = this.state.district.schools;
    schools.splice(index, 1);
    this.setState({ schools: schools }, () => {
      if (this.state.type === 'edit') {
        let district = {
          ...this.state.district,
          schools: this.state.district.schools,
        }
        this.saveDistrict(district, { method: 'DELETE_SCHOOL', school: school });
      }
    });
  }

  removeManagerEmails = (admin, index) => {
    let tempDistrict = this.state.district;
    tempDistrict.admins.splice(index, 1);
    this.setState({ district: tempDistrict }, () => {
      if (this.state.type === 'edit') {
        let district = {
          ...this.state.district,
          admins: this.state.district.emails,
        }
        this.saveDistrict(district, { method: 'DELETE_DISTRICT', email: admin.email });
      }
    });
  }

  editSchoolData = (school, index) => {
    this.setState({ userType: "User" })
    this.setState({ districtModal: true, editEmail: school, editName: school.name, editIndex: index, editType: 'School' });
  }

  editManagerEmails = (email, index) => {
    this.setState({userType: 'Manager'})
    this.setState({ districtModal: true, editEmail: email, editIndex: index, editType: 'District' });
  }

  editAdminEmails = (email, index) => {
    this.setState({userType: 'Admin'})
    this.setState({ districtModal: true, editEmail: email, editIndex: index, editType: 'District' })
  }

  renderSchoolList = (status) => {
    let tempRenderSchoolList = this.state.district.schools.filter((item) => item.archived === status).sort((a, b) => a.name > b.name ? 1 : -1)
    return tempRenderSchoolList.length > 0 ? tempRenderSchoolList.map((school, index) => {
      return (
        <>
          <Row style={{ alignItems: 'center', marginLeft: '10px' }}>
            <>
              {this.state.type === 'create' &&
                <Button
                  className="btn-icon btn-neutral btm btn-google mr-1"
                  onClick={() => { this.removeSchoolData(school, index) }}
                >
                  <i className="now-ui-icons ui-1_simple-remove"></i>
                </Button>
              }
              {this.state.type === 'edit' &&
                <Button
                  className="btn-icon btn-neutral btm btn-twitter mr-1"
                  onClick={() => { this.editSchoolData(school, index) }}
                >
                  <i className="now-ui-icons ui-2_settings-90"></i>
                </Button>
              }
            </>
            <Row style={{ marginLeft: '10px', width: '90%' }}>
              <Col lg={6}>
                <span className="font-crm">{school.name}</span>
              </Col>
            </Row>
          </Row>
        </>
      )
    }) : <div style={{paddingLeft: "15px", fontSize: '16px'}}>No schools are archived.</div>
  }

  renderManagerEmails = (status) => {
    let tempAdmins = this.state.district.admins.filter((item) => item.archived === status).sort((a, b) => a.name > b.name ? 1 : -1)
    return tempAdmins.length > 0 ? tempAdmins.map((admin, index) => {
      return (
        <div key={"admin"+ index}>
          <Row style={{ alignItems: 'center', marginRight: '10px', marginLeft: '10px' }}>
            <>
              {this.state.type === 'create' &&
                <Button
                  className="btn-icon btn-neutral btm btn-google"
                  onClick={() => { this.removeManagerEmails(admin, index) }}
                >
                  <i className="now-ui-icons ui-1_simple-remove"></i>
                </Button>
              }
              {this.state.type === 'edit' &&
                <Button
                  className="btn-icon btn-neutral btm btn-twitter"
                  onClick={() => { this.editManagerEmails(admin, index) }}
                >
                  <i className="now-ui-icons ui-2_settings-90"></i>
                </Button>
              }
            </>
            <Row style={{ marginLeft: '10px', width: '90%' }}>
              <Col lg={6}>
                <span className="font-crm">{admin.name}</span>
              </Col>
              <Col lg={6}>
                <span className="font-crm">{admin.email}</span>
              </Col>
            </Row>
          </Row>
        </div>
      )
    }) : <div style={{ fontSize: '16px', marginBottom: '16px', paddingLeft: '15px' }}>No managers are archived.</div>
  }

  renderAdminEmails = (status) => {
    let tempData = this.state.district.emails.filter((email) => email.archived === status).sort((a, b) => a.name > b.name ? 1 : -1)
    return tempData.map((email, index) => {
      return (
        <Row key={"adminEmails" + index} style={{ alignItems: 'center', marginRight: '10px', marginLeft: '10px' }}>
          <>
          {this.state.type === 'create' &&
            <Button
              className="btn-icon btn-neutral btm btn-google"
              // onClick={() => { this.removeAdminEmails(admin, index) }}
            >
              <i className="now-ui-icons ui-1_simple-remove"></i>
            </Button>
          }
          {this.state.type === 'edit' &&
            <Button
              className="btn-icon btn-neutral btm btn-twitter"
              onClick={() => { this.editAdminEmails(email, index) }}
            >
              <i className="now-ui-icons ui-2_settings-90"></i>
            </Button>
          }
          </>
          <Row style={{ marginLeft: '10px', width: '90%' }}>
            <Col lg={6}>
              <span className="font-crm">{email.name}</span>
            </Col>
            <Col lg={6}>
              <span className="font-crm">{email.email}</span>
            </Col>
          </Row>
        </Row>
      )
    })
  }

  saveDistrictData = () => {
    if (this.state.name === '') {
      toast.info('District Name can not be empty');
      return;
    }
    let district = {
      name: this.state.district.name,
      admins: this.state.district.admins,
      schools: this.state.district.schools,
      totalObservations: this.state.district.totalObservations
    }
    if (this.state.type === 'edit') {
      district = {
        ...district,
        _id: this.state.district._id,
      }
    }
    this.props.history.push('/districts');
    this.saveDistrict(district);
  }

  onChangeAdminName = (e) => {
    this.setState({ adminName: e.target.value })
  }

  onChangeAdminEmail = (e) => {
    this.setState({ adminEmail: e.target.value })
  }

  cancelEdit = () => {
    this.setState({ districtModal: false})
  }

  render() {
    return (
      <>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <DropdownScrollNavbar  location={this.props.location} />
        <div className="wrapper">
          <div className="home">
            <Container>
              <Row>
                <div className="mr-auto ml-auto col-lg-8 col-md-12">
                  <Card className="card-raised mt-2 form-card">
                    <CardHeader>
                      {this.state.type === 'edit' ?
                        <h5 className="default-back-color page-title">
                          {
                            this.props.auth.user.role === constant['ROLE']['DISTRICT_ADMIN'] 
                            ? <span style={{color: "#648292"}}>For District</span> 
                            :"Edit/Delete District/School Admins"
                          }
                        </h5>
                        :
                        <h5 className="default-back-color page-title">
                          Add District/School
                        </h5>
                      }
                    </CardHeader>
                    <CardBody>
                      {
                        this.props.auth.user.role !== constant['ROLE']['DISTRICT_ADMIN'] 
                        ? <Col>
                            <Label className="font-crm font-crm-bold">District Name</Label>
                            <InputGroup
                              className={this.state.nameFocus ? "input-group-focus" : ""}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="now-ui-icons location_pin"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                onChange={this.onChange}
                                value={this.state.district.name}
                                placeholder="District Name"
                                id="name"
                                type="text"
                                autoComplete="off"
                                required
                                onFocus={() => this.setState({ nameFocus: true })}
                                onBlur={() => this.setState({ nameFocus: false })}
                              />
                            </InputGroup>
                          </Col>
                        : <Col>
                            <Col style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '0px'}}>
                              <Label className="font-crm font-crm-bold">Admin Emails</Label>
                              <Button
                                className="color-white border-0" style={{backgroundColor: "#cca923"}}
                                onClick={() => { this.setState({archivedStatus: !this.state.archivedStatus}) }}
                              >
                                {this.state.archivedStatus ? "My Data" : "Archived"}
                              </Button>
                            </Col>
                            {
                              this.renderAdminEmails(this.state.archivedStatus)
                            }
                            {
                              this.state.archivedStatus === false && <Row style={{ alignItems: 'flex-end', marginRight: 0, paddingLeft: '15px', paddingRight: "15px"}} className="mt-2">
                                <Col>
                                  <InputGroup
                                    className={this.state.districtNameFocus ? "input-group-focus" : ""}
                                  >
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="now-ui-icons business_bank"></i>
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                      onChange={this.onChangeAdminName}
                                      value={this.state.adminName}
                                      placeholder="Admin Name"
                                      id="adminName"
                                      type="text"
                                      autoComplete="off"
                                      required
                                      onFocus={() => this.setState({ districtNameFocus: true })}
                                      onBlur={() => this.setState({ districtNameFocus: false })}
                                    />
                                  </InputGroup>
                                </Col>
                                <Col>
                                  <InputGroup
                                    className={this.state.districtAdminFocus ? "input-group-focus" : ""}
                                  >
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="now-ui-icons ui-1_email-85"></i>
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                      onChange={this.onChangeAdminEmail}
                                      value={this.state.adminEmail}
                                      placeholder="Admin Email"
                                      id="adminEmail"
                                      type="text"
                                      autoComplete="off"
                                      onFocus={() => this.setState({ districtAdminFocus: true })}
                                      onBlur={() => this.setState({ districtAdminFocus: false })}
                                    />
                                  </InputGroup>
                                </Col>
                                <Button className="btn btn-default default-back-color" onClick={() => { this.addAdminData() }}>
                                  <i className="now-ui-icons ui-1_simple-add"></i>
                                </Button>
                              </Row>
                            }
                          </Col>
                      }
                      <Col>
                        <Col style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between',  paddingLeft: '0px'}}>
                          <Label className="font-crm font-crm-bold">
                            {
                              this.props.auth.user.role === constant['ROLE']['DISTRICT_ADMIN'] ? "Managers" : "District Admin Emails"
                            }
                          </Label>
                          {
                            this.props.auth.user.role !== constant['ROLE']['DISTRICT_ADMIN'] && <Button
                              className="color-white border-0" style={{backgroundColor: "#cca923"}}
                              onClick={() => { this.setState({archivedStatus: !this.state.archivedStatus}) }}
                            >
                              {this.state.archivedStatus ? "My Data" : "Archived"}
                            </Button>
                          }
                          
                        </Col>
                        {
                          this.props.auth.user.role === constant['ROLE']['DISTRICT_ADMIN'] 
                          ? this.renderManagerEmails(this.state.archivedStatus)
                          : this.renderAdminEmails(this.state.archivedStatus)
                        }
                        {
                          this.state.archivedStatus === false && <Row style={{ alignItems: 'flex-end', marginRight: 0, paddingLeft: '15px', paddingRight: '15px' }} className="mt-2">
                            <Col>
                              <InputGroup
                                className={this.state.districtNameFocus ? "input-group-focus" : ""}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="now-ui-icons business_bank"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  onChange={this.onChange}
                                  value={this.state.districtName}
                                  placeholder={this.props.auth.user.role === constant['ROLE']['DISTRICT_ADMIN'] ? "Manager Name" : "District Admin Name"}
                                  id="districtName"
                                  type="text"
                                  autoComplete="off"
                                  required
                                  onFocus={() => this.setState({ districtNameFocus: true })}
                                  onBlur={() => this.setState({ districtNameFocus: false })}
                                />
                              </InputGroup>
                            </Col>
                            <Col>
                              <InputGroup
                                className={this.state.districtAdminFocus ? "input-group-focus" : ""}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="now-ui-icons ui-1_email-85"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  onChange={this.onChange}
                                  value={this.state.email}
                                  placeholder={this.props.auth.user.role === constant['ROLE']['DISTRICT_ADMIN'] ? "Manager Email" : "District Admin Email"}
                                  id="email"
                                  type="text"
                                  autoComplete="off"
                                  onFocus={() => this.setState({ districtAdminFocus: true })}
                                  onBlur={() => this.setState({ districtAdminFocus: false })}
                                />
                              </InputGroup>
                            </Col>
                            <Button className="btn btn-default default-back-color" onClick={() => { this.addDistrictData() }}>
                              <i className="now-ui-icons ui-1_simple-add"></i>
                            </Button>
                          </Row>
                        }
                      </Col>
                      <Col>
                        <Label className="font-crm font-crm-bold">List of Schools</Label>
                        {
                          this.renderSchoolList(this.state.archivedStatus)
                        }
                        {
                          this.state.archivedStatus === false && <Row style={{ alignItems: 'flex-end', marginRight: 0, paddingLeft: '15px', paddingRight: '15px' }} className="mt-2">
                            <Col>
                              <InputGroup
                                className={this.state.schoolNameFocus ? "input-group-focus" : ""}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="now-ui-icons business_bank"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  onChange={this.onChange}
                                  value={this.state.schoolName}
                                  placeholder="School Name Here"
                                  id="schoolName"
                                  type="text"
                                  autoComplete="off"
                                  required
                                  onFocus={() => this.setState({ schoolNameFocus: true })}
                                  onBlur={() => this.setState({ schoolNameFocus: false })}
                                />
                              </InputGroup>
                            </Col>
                            <Button className="btn btn-default default-back-color" onClick={() => { this.addSchoolData() }}>
                              <i className="now-ui-icons ui-1_simple-add"></i>
                            </Button>
                          </Row>
                        }
                      </Col>
                      {
                        this.state.archivedStatus === false && this.props.auth.user.role !== constant['ROLE']['DISTRICT_ADMIN'] && <Col>
                          <Label className="font-crm font-crm-bold">Total Observations</Label>
                          <ButtonGroup style={{ alignItems: 'center', display: 'block' }}>
                            <Button className="default-back-color" size="sm" onClick={() => { this.updateTotalUserCount(false) }}>
                              <i className="now-ui-icons ui-1_simple-delete"></i>
                            </Button>
                            <Input
                              className="font-crm font-crm-bold color-alert ml-3 mr-3 total-observation"
                              onChange={this.onChange}
                              value={this.state.district.totalObservations}
                              id="totalObservations"
                              type="number"
                              autoComplete="off"
                              required
                            />
                            <Button className="default-back-color" size="sm" onClick={() => { this.updateTotalUserCount(true) }}>
                              <i className="now-ui-icons ui-1_simple-add"></i>
                            </Button>
                          </ButtonGroup>
                        </Col>
                      }
                      
                    </CardBody>
                    <CardFooter style={{ justifyContent: 'flex-end' }}>
                      <Button
                        className="red-alert-color font-crm mr-2"
                        onClick={() => { this.props.history.push("/districts"); }}
                      >
                        Back
                      </Button>
                      <Button
                        className="default-back-color font-crm"
                        onClick={() => this.saveDistrictData()}
                      >
                        Save
                      </Button>
                    </CardFooter>
                  </Card>
                </div>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
        <DistrictModal
          districtModal={this.state.districtModal}
          type={this.state.editType}
          name={this.state.editName}
          email={this.state.editEmail}
          index={this.state.editIndex}
          userType={this.state.userType}
          closeDistrictModal={() => { this.setState({ editIndex: -1, editName: '', editEmail: '', editType: 'School' }) }}
          updateDistrictSchoolData={(data) => { this.updateDistrictSchoolData(data) }}
          cancelEdit={this.cancelEdit}
        />
      </>
    )
  }
}

District.propTypes = {
  auth: PropTypes.object.isRequired,
}

export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(District);